import { t } from 'i18next'
import { UseFormSetError } from 'react-hook-form'

const checkMakeNumberEmpty = (old: string, newVal: string): boolean => {
  if (old !== '' && newVal === '') {
    return true
  }
  return false
}

type PhoneNumbers = {
  phoneNumber: {
    old: string
    newVal: string | undefined
  }
  mobileNumber: {
    old: string
    newVal: string | undefined
  }
}

// 取引の観点で電話番号を後から空にさせたくない
// https://www.notion.so/enechain/eCloud-10031f880991809686e7f7a34066d516
export const validateEmptyNumber = (
  phoneNumbers: PhoneNumbers,
  setError: UseFormSetError<{
    phoneNumber?: string | undefined
    mobileNumber?: string | undefined
  }>,
): boolean => {
  if (
    checkMakeNumberEmpty(
      phoneNumbers.phoneNumber.old,
      phoneNumbers.phoneNumber.newVal ?? '',
    )
  ) {
    setError('phoneNumber', {
      type: 'manual',
      message: t('message.required', {
        entity: t('pages.members.label.phoneNumber'),
      }),
    })
    return false
  }
  if (
    checkMakeNumberEmpty(
      phoneNumbers.mobileNumber.old,
      phoneNumbers.mobileNumber.newVal ?? '',
    )
  ) {
    setError('mobileNumber', {
      type: 'manual',
      message: t('message.required', {
        entity: t('pages.members.label.mobileNumber'),
      }),
    })
    return false
  }
  return true
}

// 取引の観点で名前が５０文字以内になるようにチェックする。
// https://www.notion.so/enechain/eCloud-eAdmin-a70e6b5bd71741899f6f206cbf2185f1#10b31f88099180339204d97a3cf71a19
export const validateNameLength = (
  name: string | undefined,
  setError: UseFormSetError<{
    name?: string | undefined
  }>,
): boolean => {
  if (name && name.length > 50) {
    setError('name', {
      type: 'manual',
      message: t('message.lengthExceeded', {
        entity: t('pages.members.label.nameLength'),
      }),
    })
    return false
  }
  return true
}

import React, { useRef } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, FormControl, Stack, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  Input,
  Label,
  PrimaryButton,
  RadioButton,
  SemanticColors,
  Spacing,
  Term,
  TertiaryButton,
  Typography,
} from '@enechain/ecloud-designsystem'

import { ProfilePicture } from '~/components/parts/ProfilePicture'
import { Language } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'
import {
  ProfileEditSchema,
  profileEditSchema,
} from '~/pages/Profile/components/ProfileEditForm/profileEditSchema'
import { useProfileMutation } from '~/pages/Profile/hooks/useProfileMutation'
import { useResetPassword } from '~/pages/Profile/hooks/useResetPassword'
import { useUploadProfileImage } from '~/pages/Profile/hooks/useUploadProfileImage'
import { generateUint8Array } from '~/utils/img'
import {
  validateEmptyNumber,
  validateNameLength,
} from '~/validation/phonenumber'

export const ProfileEditForm: React.FC = () => {
  const { t } = useTranslation()
  const me = useLoginUser()
  const { mutate: mutateProfile, isPending: isSavingMember } =
    useProfileMutation()
  const { mutate: resetPassword, isPending: isSendingResetPasswordEmail } =
    useResetPassword()
  const { mutate: mutateProfileImage, isPending: isUploadingImage } =
    useUploadProfileImage()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<ProfileEditSchema>({
    resolver: zodResolver(profileEditSchema),
    defaultValues: {
      language: me.language,
      organizationId: me.organizationId,
      id: me.id,
    },
  })

  const onSubmit = (data: ProfileEditSchema): void => {
    if (
      !validateEmptyNumber(
        {
          phoneNumber: { old: me.phoneNumber, newVal: data.phoneNumber },
          mobileNumber: { old: me.mobileNumber, newVal: data.mobileNumber },
        },
        setError,
      )
    ) {
      return
    }
    if (!validateNameLength(data.name, setError)) {
      return
    }
    mutateProfile(data)
  }

  const onResetPassword = (): void => {
    resetPassword({ id: me.id })
  }

  const uploadImageRef = useRef<HTMLInputElement>(null)

  const handleClickInputFile = (): void => {
    const input = uploadImageRef?.current
    if (input != null) {
      input.click()
    }
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.files != null) {
      const img = event.target.files[0]
      generateUint8Array(img)
        .then((img) => {
          mutateProfileImage({
            image: img,
          })
        })
        .catch(() => {
          throw new Error(t('pages.profile.action.uploadImage.error'))
        })
    }
  }

  return (
    <Stack gap={Spacing[6]} width="100%">
      <Flex alignItems="center" gap={Spacing[2]}>
        <ProfilePicture
          // アップロードしてもファイル名は変わらないので、画像が切り替わらない。再取得できるようにクエリパラメータを付与する
          src={me.imageUrl + '?' + new Date().toISOString()}
        />
        <Input
          ref={uploadImageRef}
          aria-label="file-uploader"
          hidden
          onChange={handleFileChange}
          type="file"
        />
        <TertiaryButton onClick={handleClickInputFile} size="sm">
          {isUploadingImage
            ? t('pages.profile.action.uploadImage.isUploading')
            : t('pages.profile.action.uploadImage.button')}
        </TertiaryButton>
      </Flex>
      <Flex
        padding={Spacing[6]}
        border="1px"
        borderColor={SemanticColors.Border.lowEmphasis}
        borderRadius={BorderRadius.radiiMd}
      >
        <Flex flexDirection="column" gap={Spacing[6]} width="50%">
          <Term>
            <Label required>{t('pages.members.label.name')}</Label>
            <Input
              defaultValue={me.name}
              {...register('name')}
              error={errors.name?.message}
              placeholder={t('pages.members.placeholder.name')}
            />
          </Term>

          <Term>
            <Label required>{t('pages.members.label.email')}</Label>
            <Text width="200%">{me.email}</Text>
            <Text
              {...Typography.textSm}
              width="200%"
              color={SemanticColors.Text.lowEmphasis}
              whiteSpace="prewrap"
            >
              {t('pages.profile.action.email_readonly.description')}
            </Text>
          </Term>

          <Term>
            <Label>{t('pages.members.label.password')}</Label>
            <TertiaryButton
              width="300px"
              maxWidth="200%"
              whiteSpace="prewrap"
              isLoading={isSendingResetPasswordEmail}
              onClick={onResetPassword}
            >
              {t('action.change_something', {
                entity: t('pages.members.label.password'),
              })}
            </TertiaryButton>
            <Text
              {...Typography.textSm}
              width="200%"
              color={SemanticColors.Text.lowEmphasis}
            >
              {t('pages.profile.action.resetPassword.description')}
            </Text>
          </Term>

          <FormControl>
            <Controller
              control={control}
              name="language"
              render={({ field: { onChange, value } }): React.ReactElement => (
                <Term>
                  <Label required>{t('pages.members.label.language')}</Label>
                  <RadioButton
                    defaultValue={me.language.toString()}
                    onChange={onChange}
                    radios={[
                      {
                        label: t('pages.members.value.japanese'),
                        value: Language.JAPANESE.toString(),
                      },
                      {
                        label: t('pages.members.value.english'),
                        value: Language.ENGLISH.toString(),
                      },
                    ]}
                    value={value.toString()}
                  />
                </Term>
              )}
            />
          </FormControl>

          <Term>
            <Label>{t('pages.members.label.phoneNumber')}</Label>
            <Input
              defaultValue={me.phoneNumber}
              {...register('phoneNumber')}
              error={errors.phoneNumber?.message}
            />
          </Term>

          <Term>
            <Label>{t('pages.members.label.mobileNumber')}</Label>
            <Input
              defaultValue={me.mobileNumber}
              {...register('mobileNumber')}
              error={errors.mobileNumber?.message}
            />
          </Term>
        </Flex>
      </Flex>

      <Flex justifyContent="right" gap={Spacing[2]} width="100%">
        <PrimaryButton
          isLoading={isSavingMember}
          onClick={handleSubmit(onSubmit)}
        >
          {t('action.save')}
        </PrimaryButton>
      </Flex>
    </Stack>
  )
}
